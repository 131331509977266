import { useFormik } from 'formik';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import Button from '../../../../components/button';
import Card, { CardTitle, CardFooter } from '../../../../components/card';
import Input from '../../../../components/input';
import { ToggleButton } from '../../../../components/toggle-button';
import { companyInformationSchema } from '../../../../schemas/company-information';
import { updateProducer } from '../../../../services/producerService';
import './styles.scss';

const CompanyInformation = ({ user, isPremium }) => {
  const [updatedMessage, setUpdatedMessage] = useState(null);

  useEffect(() => {
    if (updatedMessage) {
      setTimeout(() => setUpdatedMessage(null), 5000);
    }
  }, [updatedMessage]);

  // const handleFilter = useCallback(
  //   (filter) => {
  //     let filteredSuggestions = regionSuggestionsCopy;
  //     if (filter.trim().length > 0) {
  //       filteredSuggestions = regionSuggestionsCopy.filter((suggesstion) => {
  //         let { name } = suggesstion;
  //         name = name.toLowerCase();
  //         return name.includes(filter.toLowerCase());
  //       });
  //     }
  //     setRegionSuggestions(filteredSuggestions);
  //     return filteredSuggestions;
  //   },
  //   [regionSuggestionsCopy]
  // );

  const standardUserFields = {
    name: '',
    email: '',
    country: 'Italia',
    region: '',
    idRegion: '',
    province: '',
    city: '',
    zipCode: '',
    address: '',
    phone: '',
  };
  const premiumUserField = {
    ...standardUserFields,
    yearFoundation: '',
    wineProperty: '',
    agronomo: '',
    enologo: '',
    ettari: '',
    bottlesProduced: '',
    viticoltura: '',
    canBeVisited: '',
    isDirectSell: '',
    companyTasting: '',
  };

  const changeFieldValues = useCallback(() => {
    formik.setFieldValue('name', user.name ? user.name : '');
    formik.setFieldValue('email', user.email ? user.email : '');
    formik.setFieldValue('region', user.region ? user.region.name : '');
    formik.setFieldValue('idRegion', user.region ? user.region.id : '');
    formik.setFieldValue('province', user.province ? user.province : '');
    formik.setFieldValue('city', user.city ? user.city : '');
    formik.setFieldValue('zipCode', user.zipCode ? user.zipCode : '');
    formik.setFieldValue('address', user.address ? user.address : '');
    formik.setFieldValue('phone', user.phone ? user.phone : '');
    if (isPremium) {
      formik.setFieldValue(
        'yearFoundation',
        user.yearFoundation ? user.yearFoundation : ''
      );
      formik.setFieldValue(
        'wineProperty',
        user.wineProperty ? user.wineProperty : ''
      );
      formik.setFieldValue('agronomo', user.agronomo ? user.agronomo : '');
      formik.setFieldValue('enologo', user.enologo ? user.enologo : '');
      formik.setFieldValue('ettari', user.ettari ? user.ettari : '');
      formik.setFieldValue(
        'bottlesProduced',
        user.bottlesProduced ? user.bottlesProduced : ''
      );
      formik.setFieldValue(
        'viticoltura',
        user.viticoltura ? user.viticoltura : ''
      );
      formik.setFieldValue('canBeVisited', user.canBeVisited);
      formik.setFieldValue('isDirectSell', user.isDirectSell);
      formik.setFieldValue('companyTasting', user.companyTasting);
    }
  }, [user]);

  const formik = useFormik({
    initialValues: isPremium ? premiumUserField : standardUserFields,
    validationSchema: companyInformationSchema,
    onSubmit: (values) => {
      updateProducer(values, user, isPremium)
        .then(() => setUpdatedMessage('Profilo aggiornato'))
        .catch(() => setUpdatedMessage("C'è stato un errore"));
    },
  });

  useLayoutEffect(() => {
    changeFieldValues();
  }, [changeFieldValues]);

  return (
    <div className='company-information'>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardTitle>Informazioni Azienda</CardTitle>
          <div className='flex flex-row justify-between items-start mb-6'>
            <Input
              placeholder='E-mail'
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              styles={'mr-2'}
              errorMessage={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
            <Input
              placeholder='nome azienda'
              name='name'
              type='text'
              styles='ml-2'
              value={formik.values.name}
              onChange={formik.handleChange}
              errorMessage={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : null
              }
            />
          </div>
          <div className='flex flex-row justify-between items-start mb-6'>
            <Input
              placeholder='Nazzione'
              name='country'
              value={formik.values.country}
              styles='mr-2'
              errorMessage={
                formik.touched.country && formik.errors.country
                  ? formik.errors.country
                  : null
              }
              disabled
            />
            <Input
              placeholder='Regione'
              name='region'
              value={formik.values.region}
              styles='ml-2'
              errorMessage={
                formik.touched.region && formik.errors.region
                  ? formik.errors.region
                  : null
              }
              disabled
            />
            <Input
              placeholder='Regione'
              name='region'
              value={formik.values.idRegion}
              styles='hidden'
              disabled
            />
            {/*
              Leave this for further 
              <Autocomplete
              suggestions={regionSuggestions}
              styles='ml-2'
              onSelect={(val) => {
                formik.values.region = val;
              }}
              onChange={(e) => handleFilter(e.target.value)}
              placeholder='Regione'
              name='region'
              errorMessage={
                formik.touched.region && formik.errors.region
                  ? formik.errors.region
                  : null
              }
            /> */}
          </div>
          <div className='flex flex-row justify-between items-start mb-6'>
            <Input
              placeholder='Provincia'
              type='text'
              name='province'
              value={formik.values.province}
              onChange={formik.handleChange}
              styles='mr-2'
              errorMessage={
                formik.touched.province && formik.errors.province
                  ? formik.errors.province
                  : null
              }
            />
            <Input
              placeholder='Città'
              type='text'
              name='city'
              styles='ml-2'
              value={formik.values.city}
              onChange={formik.handleChange}
              errorMessage={
                formik.touched.city && formik.errors.city
                  ? formik.errors.city
                  : null
              }
            />
          </div>
          <div className='flex flex-row justify-between items-start mb-6'>
            <div className="w-2/4 mr-2">
              <Input
                placeholder='Indirizzo'
                type='text'
                name='address'
                value={formik.values.address}
                onChange={formik.handleChange}
                errorMessage={
                  formik.touched.address && formik.errors.address
                    ? formik.errors.address
                    : null
                }
              />
            </div>
            <div className="ml-2 flex flex-row w-2/4">
              <div className='mr-2 w-2/4'>
                <Input
                  placeholder='CAP'
                  name='zipCode'
                  type='tel'
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                  errorMessage={
                    formik.touched.zipCode && formik.errors.zipCode
                      ? formik.errors.zipCode
                      : null
                  }
                />
              </div>
                <Input
                  placeholder='Telefono'
                  type='text'
                  name='phone'
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  errorMessage={
                    formik.touched.phone && formik.errors.phone
                      ? formik.errors.phone
                      : null
                  }
                />
            </div>
          </div>
          <div className='flex flex-row justify-between items-start mb-6'>
            <Input
              placeholder='Anno di fondazione'
              name='yearFoundation'
              value={formik.values.yearFoundation}
              onChange={formik.handleChange}
              errorMessage={
                formik.touched.yearFoundation && formik.errors.yearFoundation
                  ? formik.errors.yearFoundation
                  : null
              }
              maxLength='4'
              type='tel'
              min='0'
              isPremiumFeature
              disabled={!isPremium}
              styles='mr-2'
            />
            <Input
              placeholder='Propietà del vino'
              name='wineProperty'
              value={formik.values.wineProperty}
              onChange={formik.handleChange}
              disabled={!isPremium}
              isPremiumFeature
              styles='ml-2'
            />
          </div>
          <div className='flex flex-row justify-between items-start mb-6'>
            <Input
              placeholder='Agronomo'
              name='agronomo'
              value={formik.values.agronomo}
              onChange={formik.handleChange}
              disabled={!isPremium}
              styles='mr-2'
              isPremiumFeature
            />
            <Input
              placeholder='Enologo'
              name='enologo'
              value={formik.values.enologo}
              onChange={formik.handleChange}
              disabled={!isPremium}
              styles='ml-2'
              isPremiumFeature
            />
          </div>
          <div className='flex flex-row justify-between items-start mb-6'>
            <Input
              placeholder='Ettari'
              name='ettari'
              value={formik.values.ettari}
              onChange={formik.handleChange}
              disabled={!isPremium}
              styles='mr-2'
              isPremiumFeature
            />
            <Input
              placeholder='Bottiglie prodotte'
              name='bottlesProduced'
              value={formik.values.bottlesProduced}
              onChange={formik.handleChange}
              disabled={!isPremium}
              errorMessage={
                formik.touched.bottlesProduced && formik.errors.bottlesProduced
                  ? formik.errors.bottlesProduced
                  : null
              }
              type='tel'
              min='0'
              styles='ml-2'
              isPremiumFeature
            />
          </div>
          <div className='w-2/4 flex flex-row justify-between items-center mb-12'>
            <Input
              placeholder='Viticoltura'
              name='viticoltura'
              styles='mr-2'
              value={formik.values.viticoltura}
              onChange={formik.handleChange}
              disabled={!isPremium}
              isPremiumFeature
            />
          </div>

          <div className='flex flex-row mb-20'>
            <ToggleButton
              key='canBeVisited'
              name='canBeVisited'
              label='Visite in azienda'
              onChange={(value) => (formik.values.canBeVisited = value)}
              disabled={!isPremium}
              isActive={user.canBeVisited}
              isPremiumFeature
            />
            <ToggleButton
              key='isDirectSell'
              name='isDirectSell'
              styles='mx-8'
              label='Vendita diretta'
              onChange={(value) => (formik.values.isDirectSell = value)}
              disabled={!isPremium}
              isActive={user.isDirectSell}
              isPremiumFeature
            />
            <ToggleButton
              key='companyTasting'
              name='companyTasting'
              label='Degustazione in azienda'
              onChange={(value) => (formik.values.companyTasting = value)}
              disabled={!isPremium}
              isActive={user.companyTasting}
              isPremiumFeature
            />
          </div>
          {updatedMessage && (
            <p className='feedback__message'>{updatedMessage}</p>
          )}
          <CardFooter>
            <Button text='Salva' type='submit' />
          </CardFooter>
        </Card>
      </form>
    </div>
  );
};

export default CompanyInformation;
