export const storeUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const storeToken = (token) => {
  localStorage.setItem('token', token)
}

export const retrieveUser = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  return user;
}

export const retrieveToken = () => {
  const token = JSON.parse(localStorage.getItem('token'));

  return token;
}

export const removeUser = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('user');
}
