import './styles.scss';
import classnames from 'classnames';

const Checkbox = ({
  label,
  styles,
  onChange,
  status,
  name,
  disabled,
  ...inputProps
}) => {
  const fieldProps = {
    ...inputProps,
    id: name,
    name,
    onChange: onChange || null,
  };
  const classes = classnames('checkbox', styles )
  return (
    <div className='checkbox-container'>
      <input type="checkbox" className={classes} {...fieldProps} />
      <span className="checkbox__label">{label}</span>
    </div>
  );
};

export default Checkbox;