import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router"
import jwt from 'jsonwebtoken';
import { storeToken, storeUser } from "../../services/storageService";
import { retrieveUser } from "../../services/userService";
import { reloadRequestInterceptor } from "../../utils/restClient";

export const ImpersonateUserPage = () => {

  const location = useLocation();
  const history = useHistory();
  const search = location.search;

  useEffect(() => {
    const token = search.replace('?token=', '');
    storeToken(token);
    reloadRequestInterceptor(token);

    const payload = jwt.decode(token);
    const idUser = payload.id;

    retrieveUser(idUser)
      .then(res => res.data.user)
      .then(user => {
        storeUser(user);
        window.open('/#/dashboard')
        return;
      })
      .catch((err) => {
        console.error(err.response);
      });

  }, [search, history]);

  return (
    <div style={{ 
      backgroundColor: 'orange',
      width: 'fit-content',
    }}>
      <p>Opening new tab...</p>
      <p>Please close this one</p>
    </div>
  )
}
