import { UploadImage } from '../../../../../../components/upload-image';
import './styles.scss';

export const CarousellPhotos = ({ photos }) => {

  const photosArr = photos.map((photo,index) => {
    return {
      src: photo,
      name: `carrousel${index+1}`
    }
  })

  return (
    <div className="carousell-photos">
      <p className="carousell-photos__title">Carosello</p>
      <div className="carousell-photos__content">
        {
          photosArr.length > 0 && photosArr.map(
            (photo) => (
              <div key={photo.name} className="carousell-photo">
                <UploadImage fieldName={photo.name} image={photo.src} />
              </div>
            )
          )
        }
      </div>
    </div>
  )
}