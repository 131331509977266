
import ReactDom from 'react-dom';
import './styles.scss';

export const Modal = ({ children, show, onClose }) => {
  
  if (!show) {
    return null;
  }

  
  return ReactDom.createPortal((
    <div className="modal" role="dialog" aria-modal="true" onClick={() => onClose()}>
      <div className="modal__container" >
        <div className="modal__overlay" aria-hidden="true"></div>
        
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div className="modal__content" onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>
  ), document.getElementById('root'))
} 