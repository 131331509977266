import { authContext } from "../../contexts/auth";
import { useProvideUser } from "../../hooks/useProvideUser"

export const AuthProvider = ({ children }) => {
  const auth = useProvideUser();

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
} 