import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import MultipleUsers from '../../assets/images/multiple-users.svg';
import { useAuth } from '../../hooks/useAuth';
import { resetPassword } from '../../services/authService';
import { PopupMenu } from '../popup-menu';
import './styles.scss';

export const UserMenu = ({ user }) => {
  
  const [showPopup, setShowPopup] = useState(false);
  const [changePasswordMsg, setChangePasswordMsg] = useState();
  const [isSsubmiting, setIsSubmiting] = useState(false)
  const history = useHistory();
  const { logOut } = useAuth();
  const handlePopup = () => {
    setShowPopup(!showPopup);

    return showPopup;
  };

  const handleChangePassword = () => {
    setIsSubmiting(true);
    if (!isSsubmiting) {
      resetPassword()
        .then(() => setChangePasswordMsg('Per favore, controlla la tua email'))
        .catch(() => setChangePasswordMsg("C'è stato un errore"))
        .finally(() => setTimeout(() => setChangePasswordMsg(null), 5000))
        .finally(() => setIsSubmiting(false));
    }
  };

  const handleLogOut = () => {
    logOut();
    history.push('/login');
  }

  return (
    <div className='user-menu__container' onClick={() => handlePopup()}>
      <img
        className='user-menu__icon'
        width='36'
        src={MultipleUsers}
        alt='user-menu'
      />
      <PopupMenu show={showPopup}>
        <div className='user-menu__user-info'>
          <img
            className='content__profile'
            src={MultipleUsers}
            alt='user-icon'
            width='54'
          />
          <div>
            <p className='content__fullname'>{`${user.firstName} ${user.lastName}`}</p>
            <p className='content__email'>{user.email}</p>
          </div>
        </div>
        <div className='user-menu__options'>
          <p
            className='user-menu__options__link font-secondary text-sm mb-2 cursor-pointer'
            onClick={handleChangePassword}
          >
            {changePasswordMsg ? changePasswordMsg : 'Cambia password'}
          </p>
          {/* <Link to='/contact-form'>
            <p className='user-menu__options__link font-secondary text-sm text-black'>
              Ottieni aiuto
            </p>
          </Link> */}
        </div>
        <p className='user-menu__options__link font-secondary text-sm mb-2 cursor-pointer mt-4' onClick={handleLogOut}>
          Esci
        </p>
      </PopupMenu>
    </div>
  );
};
