import { useHistory, Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import Input from '../../components/input';
import Checkbox from '../../components/checkbox';
import './styles.scss';
import Button from '../../components/button';
import { useFormik } from 'formik';
import { loginService } from '../../services/authService';
import { storeToken, storeUser } from '../../services/storageService';
import { useAuth } from '../../hooks/useAuth';
import { ERROR_MESSAGES, REGEX } from '../../constants';
import { useState } from 'react';


const notActivatedMessage = 'Un amministratore deve approvare la tua richiesta. Riceverai una email quando i tuoi dati saranno validati.';

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Email è obbligatorio';
  } else if (!REGEX.EMAIL.test(values.email)) {
    errors.email = 'Email invalido'
  }
  if (!values.password) {
    errors.password = 'Password è obbligatoria';
  }

  return errors;
}

const Login = () => {
  const [apiError, setapiError] = useState(null);
  let history = useHistory();
  const {setUser} = useAuth();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate,
    onSubmit: (values) => {
      if (values) {
        setapiError(null);
        loginService({ ...values }).then((resp) => {

          const {user, jwt: token} = resp.data;
          storeUser(user);
          storeToken(token)
          setUser(user);
          setapiError(null);
          history.push('dashboard');

        }).catch(error => {

          let response = error.response;
          let errorMessage = response.data.error;
          if (errorMessage.includes('not active')) {
            setapiError(notActivatedMessage)
          } else if (
            errorMessage.includes('Invalid credentials') ||
            errorMessage.includes('User not found')
          ) {
            setapiError(ERROR_MESSAGES.INVALID_CREDENTIALS)
          } else {
            setapiError(errorMessage);
          }

        })
      }
      
    },
  });
  return (
    <div className='login'>
      <div className='login__logo'>
        <img alt='logo' src={Logo} />
      </div>
      <h2 className='login__title'>Accedi</h2>
      <p className='login__subtitle'>
        O{' '}
        <Link className='login__subtitle--link' to='/register'>
          registrati
        </Link>
      </p>
      <div className='login__form'>
        <form onSubmit={formik.handleSubmit}>
          <Input
            type='email'
            name='email'
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={formik.touched.email && formik.errors.email ? formik.errors.email : null}
            placeholder='e-mail address'
          />
          <Input
            styles='mt-6 mb-4'
            type='password'
            name='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={formik.touched.password && formik.errors.password ? formik.errors.password : null}
            placeholder='password'
          />
          <div className='flex w-full items-center justify-between mb-8'>
            <Checkbox type='checkbox' name='remember' label='Ricordarmi' />
            <Link className='link' to='/forget-password'>
              ho dimenticato la password
            </Link>
          </div>
          <p className='error w-full text-left font-secondary text-sm mb-1'>{apiError}</p>
          <Button maxWidth type='submit' text='Accedi' color='primary' />
          <p className='w-full text-left font-secondary text-sm mt-3.5 mb-1'>
            Non hai un account?
          </p>
          <Button
            maxWidth
            onClick={() => history.push('/register')}
            type='button'
            text='Registrati'
            color='secondary'
          />
        </form>
      </div>
    </div>
  );
};

export default Login;
