import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import { Autocomplete } from '../../../../components/autocomplete';
import './styles.scss';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { getProducers } from '../../../../services/producerService';
import { registerService } from '../../../../services/authService';
import { REGEX } from '../../../../constants';

const RegisterForm = ({ setIsRegistered }) => {
  const history = useHistory();
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsCopy, setSuggestionsCopy] = useState();

  useLayoutEffect(() => {
    getProducers().then((resp) => {
      const {
        data: { producers },
      } = resp;
      setSuggestions(producers);
      setSuggestionsCopy(producers);
    });
  }, []);

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = 'Tuo nome è obbligatorio';
    }
    if (!values.lastName) {
      errors.lastName = 'Tuo cognome è obbligatorio';
    }
    if (!values.email) {
      errors.email = 'Tuo email è obbligatorio';
    } else if (
      !REGEX.EMAIL.test(values.email)
    ) {
      errors.email = 'Indirizzo email invalido';
    }
    if (!values.phone) {
      errors.phone = 'Tuo numero di telefono è obbligatorio';
    } else if (!REGEX.ONLYNUMBERS.test(values.phone)) {
      errors.phone = 'Inserisci un numero di telefono valido';
    }
    if (!values.producer) {
      errors.producer = 'Per favore seleziona un produttore';
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      producer: '',
    },
    validate,
    onSubmit: (values) => {
      registerService({ ...values }).then((resp) => {
        if (resp.status === 201) {
          setIsRegistered(true);
        }
      }).catch(e => {
        alert("C'è stato un errore");
      });
    },
  });

  const handleFilter = useCallback(
    (filter) => {
      let filteredSuggestions = suggestionsCopy;
      if (filter.trim().length > 0) {
        filteredSuggestions = suggestionsCopy.filter((suggesstion) => {
          let { name } = suggesstion;
          name = name.toLowerCase();
          return name.includes(filter.toLowerCase());
        });
      }
      setSuggestions(filteredSuggestions);
      return filteredSuggestions;
    },
    [suggestionsCopy]
  );

  return (
    <div className='register-form'>
      <form
        autocomplete='off'
        className='w-full'
        onSubmit={formik.handleSubmit}
      >
        <div className='flex flex-row justify-between w-full'>
          <Input
            styles='mr-2'
            type='text'
            name='firstName'
            placeholder='nome*'
            value={formik.values.firstName}
            onChange={formik.handleChange}
            errorMessage={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
          />
          <Input
            styles='ml-2'
            type='text'
            name='lastName'
            value={formik.values.lastName}
            onChange={formik.handleChange}
            placeholder='cognome*'
            errorMessage={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null}
          />
        </div>
        <Input
          styles='mt-6'
          type='text'
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder='e-mail*'
          errorMessage={formik.touched.email && formik.errors.email ? formik.errors.email : null}
        />
        <Input
          styles='mt-6'
          type='tel'
          name='phone'
          placeholder='telefono*'
          value={formik.values.phone}
          onChange={formik.handleChange}
          errorMessage={formik.touched.phone && formik.errors.phone ? formik.errors.phone : null}
        />
        <Autocomplete
          suggestions={suggestions}
          styles='mt-6'
          onSelect={(val) => (formik.values.producer = val)}
          onChange={(e) => handleFilter(e.target.value)}
          placeholder='produttore*'
          name='producer'
          errorMessage={formik.touched.producer && formik.errors.producer ? formik.errors.producer : null}
        />
        <div className='flex w-full mt-2.5 justify-end mb-8'>
          <p className='text-sm'>
            Non trovi il produttore?{' '}
            <Link className='link' to='/contact-form'>
              Contattaci
            </Link>{' '}
          </p>
        </div>
        <Button maxWidth type='submit' text='Registrati' color='primary' />
        <p className='w-full text-left text-sm mt-3.5 mb-1'>
          Hai già un account?
        </p>
        <Button
          maxWidth
          onClick={() => history.push('/login')}
          type='button'
          text='Accedi'
          color='secondary'
        />
      </form>
    </div>
  );
};

export default RegisterForm;
