import Button from '../../../../components/button';
import './styles.scss';

const ProducerWinesLinkBar = ({ link }) => {
  return (
    <div className="producer-wines__container">
      <div className="producer-wines__access">
        <p className="producer-wines__text">Elenco dei nostri vini</p>
        <Button type="link" href={ link } styles="producer-wines__button" text="Vini" />
      </div>
    </div>
  )
}

export default ProducerWinesLinkBar;