import './styles.scss';
import Logo from '../../assets/images/logo.svg';
import RetrievePassword from './components/RetrievePassword';
import RetrievePasswordMessage from './components/RetrievePasswordMessage';
import { useState } from 'react';
import classnames from 'classnames';

const ForgetPassword = () => {
  const [retrievedPassword, setRetrievedPassword] = useState(false);
  return (
    <div className='forget-password'>
      <div className='forget-password__logo'>
        <img alt='logo' src={Logo} />
      </div>
      <h2 className='forget-password__title'>Recupero password</h2>
      <p
        className={classnames('forget-password__subtitle', {
          invisible: retrievedPassword,
        })}
      >
        Se hai dimenticato la password del tuo account quest'area ti permette di
        recuperarla.
      </p>
      {!retrievedPassword ? (
        <div className='forget-password__form'>
          <RetrievePassword setRetrievedPassword={setRetrievedPassword} />
        </div>
      ) : (
        <RetrievePasswordMessage />
      )}
    </div>
  );
};

export default ForgetPassword;
