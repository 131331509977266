import { useParams } from 'react-router-dom';
import ProducerPresentation from '../../components/producer-presentation';
import useFetchProducer from '../../hooks/useFetchProducer';
import { useFetchWines } from '../../hooks/useFetchWines';
import { getWineTastingsLink } from '../../utils/producer';
import WineCard from './wine-card';
import { WINE_TYPES_EN } from '../../constants';

const ProducerWinesPage = () => {

  let { pid } = useParams();
  const [producer, isFetching] = useFetchProducer(pid);
  const [wines, isFetchingWines] = useFetchWines(pid);

  const wineCards = wines.map(w => {
    let wineType, linkTastings, region;
    if (w.wineType && w.wineType.name) wineType = WINE_TYPES_EN[w.wineType.name];
    if (w.region && w.region.name) region = w.region.name;
    linkTastings = getWineTastingsLink({ producerId: producer.id, wineId: w.id });

    return (
      <WineCard
        name={w.name}
        producerName={producer.name}
        type={wineType}
        denominationName={region}
        linkShop={w.linkShop}
        linkTastings={linkTastings}
      />
    )
  });

  return (
    <ProducerPresentation producer={producer} isFetching={isFetching}>
      <div>
        <h5 className='producer-company__title'>Our wines</h5>
        <div class="w-full justify-center flex flex-row flex-wrap">
          { wineCards }
        </div>
      </div>
    </ProducerPresentation>
  )
}

export default ProducerWinesPage;