import { useFormik } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../../components/button';
import Input from '../../../components/input';
import { forgotPassword } from '../../../services/authService';
import './retrievePassword.scss';

const RetrievePassword = ({ setRetrievedPassword }) => {
  const history = useHistory();
  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Email is required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Enter a valid email';
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate,
    onSubmit: (values) => {
      forgotPassword({ ...values }).then(resp => {
        if (resp.status === 200) {
          setRetrievedPassword(true);
        }
      }).catch(error => {
        if (error.response.status === 400) {
          console.log('mail not found');
        }
      })
    },
  });
  return (
    <form
      className='retrieve-password'
      onSubmit={formik.handleSubmit}
    >
      <h4 className='form__title'>Inserisci la tua e-mail</h4>
      <p className='form__subtitle'>
        Inserisci l'indirizzo completo (ad es. mario.rossi@libero.it)
      </p>
      <Input
        type='email'
        name='email'
        onChange={formik.handleChange}
        errorMessage={
          formik.touched.email && formik.errors.email
            ? formik.errors.email
            : null
        }
        placeholder='e-mail address'
      />
      <div className='flex w-full mt-6 justify-between mb-8'>
        <Button
          styles='form__button'
          onClick={() => history.goBack()}
          type='button'
          text='Annulla'
          color='default'
        />
        <Button
          styles='form__button'
          type='submit'
          text='Prosegui'
          color='primary'
        />
      </div>
      <p className='form__footer'>
        Non riesci a fare il recupero password?{' '}
        <Link className='link' to='/contact-form'>
          Contattaci
        </Link>
      </p>
    </form>
  );
};

export default RetrievePassword;
