import Button from "../../components/button";
import BottleImage from "../../assets/images/borgognotta.png"

export const LabelText = ({ label='', text='-', sizeLabel='md', sizeText='md' }) => {

  return (
    <div className="mt-2 mb-2">
      <span className={`text-${sizeLabel} text-gray-600 font-regular`}>{label}</span>
      <p className={`text-${sizeText} text-gray-600 font-light`}>{text}</p>
    </div>
  )
}

const WineCard = ({ name, producerName, type, denominationName, isBio, linkShop='', linkTastings='' }) => {
  return (
    <div className="m-5 px-5">
      <div className="bg-white max-w-lg rounded-2xl px-10 py-8 shadow-lg hover:shadow-2xl transition duration-500">
        <div className="flex">
          <div className="pr-10 w-4/12">
            <img className="max-h-80 max-w-20" alt='Bottle icon' src={BottleImage}></img>
          </div>
          <div className="w-8/12">
            <LabelText label="Producer" text={producerName} sizeText="lg" />
            <LabelText label="Wine name" text={name || '-'} sizeText="lg" />
            <LabelText label="Type" text={type} />
            <LabelText label="Designation of origin" text={denominationName} />
          </div>
        </div>
        <div className="pt-4 flex justify-center md:justify-between flex-wrap ">
          <Button type='link' href={linkTastings} text="Tasting notes" />
          <Button type='link' href={linkShop} text="Shop" />
        </div>
      </div>
    </div>
  )
}

export default WineCard;

