import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

export const Collapse = ({ title='', children }) => {

  const [isActive, setIsActive ] = useState(false);
  const icon = isActive ? faAngleUp : faAngleDown;

  const handleClick = () => {
    setIsActive(!isActive);
  }

  return (
    <div className='rounded-lg bg-tertiary'>
      <header className='flex justify-between px-4 py-2' onClick={handleClick}>
        <div className=''>{title}</div>
        <FontAwesomeIcon className='h-auto' icon={icon} />
      </header>
      <div hidden={!isActive} className='bg-gray-100 transition duration-400 ease bg-gray px-4 py-2'>{children}</div>
    </div>
  )
}