import { restClient } from "../utils/restClient";

export const getProducer = ({ producerId }) => {
  
  return restClient.get(`/website/producers/${producerId}`);
};

export const getWine = (idWine) => {
  return restClient.get(`/wines/${idWine}`);
}

export const getWinesFromProducer = (idProducer) => {
  return restClient.get(`/producers/${idProducer}/wines`);
};

export const getTastingsFromWine = (idWine) => {
  return restClient.get(`/wines/${idWine}/tastings`);
};
