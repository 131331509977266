import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProducerPresentation from '../../components/producer-presentation';
import useFetchProducer from '../../hooks/useFetchProducer';
import { useFetchWine, useFetchWineTastings } from '../../hooks/useFetchWines';
import TastingCard from './components/tasting-card';
// import './styles.scss';

const WineTastingsPage = () => {

  let { pid, wid } = useParams();
  const [wineName, setWineName] = useState('Tastings')
  const [producerName, setProducerName] = useState('')
  const [producer, isFetching] = useFetchProducer(pid);
  const [wine, isFetchingWine] = useFetchWine(wid);
  const [tastings, isFetchingTastings] = useFetchWineTastings(wid);

  useEffect(() => {

    if(producer && producer.name) {
      setWineName(producer.name);
      setProducerName(producer.name)
    }

    if (wine && wine.name) {
      setWineName(wine.name);
    } 

  }, [wine, producer])


  const tastingCards = tastings.map(t => {

    return (
      <TastingCard
        wineName={wineName}
        producerName={producerName}
        appellation={t.denomination && t.denomination.name} 
        isBio={t.isBio}
        vintage={t.vintage}
        note={t.note}
        note_en={t.note_en}
        score={t.score}
        linkSticker={t.linkSticker}
        linkCertificate={t.linkCertificate}
        linkShop={wine && wine.linkShop} 
      />
    )
  });


  return (
    <ProducerPresentation producer={producer} isFetching={isFetching}>
      <div class="">
        <h5 className='producer-company__title'>{wineName}</h5>
        <div class="w-full justify-center flex flex-row flex-wrap">
          { tastingCards }
        </div>
      </div>
    </ProducerPresentation>
  )
}

export default WineTastingsPage;