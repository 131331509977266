import PasswordValidtor from 'password-validator';

export const passwordValidator = (value) => {

  const schema = new PasswordValidtor();

  schema
    .is().min(8)
    .is().max(100)
    .has().digits(1)
    .has().symbols(1)
    .has().uppercase(1)
    .has().not().spaces()
  
  
  return schema.validate(value);
} 