import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const restClient = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*'
  },

})

function reloadRequestInterceptor(token) {
  restClient.interceptors.request.use(
    config => {
      if (!token) token = localStorage.getItem('token');
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

}


restClient.interceptors.response.use((response) => {
  return response
}, (error) => {
  if(error.response.status === 401) {
    window.location = '/#/login'
  } else {
    throw error;
  }
});

reloadRequestInterceptor();

export { restClient, reloadRequestInterceptor };
