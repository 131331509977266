import { restClient } from "../utils/restClient";

export const addProducer = ({ email, message }) => {
  
  const body = {
    email,
    message
  };

  return restClient.post('/users/requests/add_producer', body);
}

export const upgradePlan = () => {
  return restClient.post('/users/requests/upgrade_plan');
}

export const retrieveUser = (id) => {
  return restClient.get('/users/' + id);
}