import { useEffect, useState } from "react";
import { getProducer } from '../services/visitor';

const useFetchProducer = (producerId) => {
  const [producer, setProducer] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  
  useEffect(() => {
    if (Number(producerId)) {
      async function fetchData() {
        setIsFetching(true)
        const { data } = await getProducer({ producerId });
        setProducer(data.producer);
        setIsFetching(false);
      }

      fetchData();
    }
  }, [producerId])
  
  return [producer, isFetching];
}

export default useFetchProducer;