import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';

const LabelText = ({ label='', text=''}) => {
  return (
    <div className='tech-stuff flex flex-col'>
      <span className='label'>{label}</span>
      <p className='text'>{text || 'N/A'}</p>
    </div>
  )
}

const ProducerAdditionalInfo = ({
  canBeVisited,
  companyTasting,
  isDirectSell,
  linkMarketplace,
  wineProperty,
  viticoltura,
  agronomo,
  ettari,
  enologo,
  bottlesProduced
}) => {
  return (
    <div className='producer-additional-info'>
      <h5 className='title'>Additional info</h5>
      <div className='flex flex-row mt-12 justify-center'>
        <div className='w-1/2 flex flex-row flex-wrap'>
          <div className='icon-container'>
            <FontAwesomeIcon
              className={canBeVisited ? 'icon--up' : 'icon--down'}
              icon={canBeVisited ? faThumbsUp : faThumbsDown}
            />
            <p className='text'>Visit to the winery</p>
          </div>
          <div className='icon-container'>
            <FontAwesomeIcon
              className={linkMarketplace ? 'icon--up' : 'icon--down'}
              icon={linkMarketplace ? faThumbsUp : faThumbsDown}
            />
            <p className='text'>Online shop</p>
          </div>
          <div className='icon-container'>
            <FontAwesomeIcon
              className={companyTasting ? 'icon--up' : 'icon--down'}
              icon={companyTasting ? faThumbsUp : faThumbsDown}
            />
            <p className='text'>Tasting in the winery</p>
          </div>
          <div className='icon-container'>
            <FontAwesomeIcon
              className={isDirectSell ? 'icon--up' : 'icon--down'}
              icon={isDirectSell ? faThumbsUp : faThumbsDown}
            />
            <p className='text'>Direct Sales in Winery</p>
          </div>
        </div>
      </div>
      <div className='producer-tech-stuff'>
        <h5 className='text-xl font-bold'>Tech Stuff</h5>
        <div className='tech-stuff-container'>
          <div className='w-1/2 flex flex-row flex-wrap'>
          
            <LabelText label="Property" text={wineProperty || 'N/A'}/>
            <LabelText label="Viticultura" text={viticoltura || 'N/A'}/>
            <LabelText label="Agronomist" text={agronomo || 'N/A'}/>
            <LabelText label="Hectares of Vineyards" text={ettari || 'N/A'}/>
            <LabelText label="Winemaker" text={enologo || 'N/A'}/>
            <LabelText label="Bottles produced" text={bottlesProduced || 'N/A'}/>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProducerAdditionalInfo;
