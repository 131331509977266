import { useEffect, useRef, useState } from 'react';
import UserImage from '../../../../../../assets/images/user.svg';
import Button from '../../../../../../components/button';
import { ERROR_MESSAGES } from '../../../../../../constants';
import { uploadImage } from '../../../../../../services/fileService';
import './styles.scss';

export const ProfileLogo = ({ logo }) => {
  const [error, setError] = useState(false);
  const [logoSrc, setSelectedImage] = useState(logo);

  useEffect(() => {
    setSelectedImage(logo);
  }, [logo]);

  const fileInputRef = useRef(null);
  const handleFileSubmit = () => {
    const maxSize = 1024 * 1024 * 5;
    const file = fileInputRef.current ? fileInputRef.current.files[0] : null;

    if (file) {
      setError(false)
      if (file.size > maxSize) {
        return setError(ERROR_MESSAGES.IMAGE_SIZE);
      }
      setSelectedImage(URL.createObjectURL(file));
    }
  };
  const handleUploadImage = () => {
    if (logoSrc && !error) {
      const file = fileInputRef.current ? fileInputRef.current.files[0] : null;
      const fieldName = "logo";

      uploadImage({ file, fieldName })
        .then((resp) => {})
        .catch((e) => {})
    }
  }
  return (
    <div className='profile-logo'>
      <p className='profile-logo__title'>Logo</p>
      <div
        className='profile-logo__image'
        
      >
        <img
          className={logoSrc ? "profile-logo__image--selected" : 'profile-logo__image--empty'}
          style={{ backgroundImage: `url(${error ? '' : logoSrc})` }}
          src={UserImage}
          alt='logo'
          onClick={() => fileInputRef.current.click()}
        />
        <input
          className='hidden'
          type='file'
          accept='image/x-png,image/jpeg'
          ref={fileInputRef}
          onChange={handleFileSubmit}
        />
        <Button
          color='default'
          text='Cambiare'
          hierarchy='quiet'
          styles='logo__button'
          onClick={() => handleUploadImage()}
        />
      </div>
    </div>
  );
};
