import { Link, useHistory } from 'react-router-dom';
import './styles.scss';
import Logo from '../../assets/images/logo.svg';
import Input from '../../components/input';
import Button from '../../components/button';
import { Select, ListItem } from '../../components/select';
import { Autocomplete } from '../../components/autocomplete';
import { useFormik } from 'formik';
import { addProducer } from '../../services/userService';

const ContactForm = () => {
  const history = useHistory();
  const validate = values => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Email is required'
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Enter a valid email';
    }

    if (!values.message) {
      errors.message = 'Message is required';
    }
    return errors;
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      message: '',
    },
    validate,
    onSubmit: values => {
      addProducer({ ...values }).then(resp => {
        
        if (resp.status === 201) {
          history.push('/login');
        }
      }).catch(e => console.log(e.response))
    }
  });

  return (
    <div className='contact-form'>
      <div className='contact-form__logo'>
        <img alt='logo' src={Logo} />
      </div>
      <h2 className='contact-form__title'>Contattaci</h2>
      <p className='contact-form__subtitle'>
        Torna alla <Link className='contact-form__subtitle--link' to='/register'>registrazione</Link>
      </p>
      <div className='contact-form__form'>
        <form className='w-full' onSubmit={formik.handleSubmit}>
          <p className="contact-form__caption">Come posiamo aiutarti?</p>
          <Input
            styles='mt-6'
            type='email'
            name='email'
            placeholder='e-mail*'
            value={formik.values.email}
            onChange={formik.handleChange}
            errorMessage={formik.touched.email && formik.errors.email ? formik.errors.email : null}
          />
          <Input
            styles='mt-6'
            type='textarea'
            name='message'
            placeholder='Messaggio'
            rows="4"
            value={formik.values.message}
            onChange={formik.handleChange}
            errorMessage={formik.touched.message && formik.errors.message ? formik.errors.message : null}
          />
          <Button maxWidth styles="mt-9" type='submit' text='Invia' color='primary' />
          <p className='w-full text-left text-sm mt-6 mb-1'>
            Torna alla <Link className="link" to="/register">registrazione</Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
