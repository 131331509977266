const ROLES = {
  PREMIUM: 'producer_premium',
  STANDARD: 'producer_standard'
};

const REGEX = {
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  PASSWORD: /(?=[A-Za-z0-9\S]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\S])(?=.{8,}).*$/,
  URL: /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
  ONLYNUMBERS: /^[0-9]*$/,
};

const ERROR_MESSAGES = {
  EMAIL_REQUIRED: 'La tua email è obbligatoria',
  EMAIL_INVALID: 'La email è invalida',
  COUNTRY_REQUIRED: 'Inserisci un paese',
  REGION_REQUIRED: 'Inserisci una regione',
  PROVINCE_REQUIRED: 'Inserisci una provincia',
  CITY_REQUIRED: 'Inserisci una città',
  ZIPCODE_REQUIRED: 'Inserisci un CAP',
  ADDRESS_REQUIRED: 'Inserisci un indirizzo',
  PHONE_REQUIRED: 'Inserisci un numero di telefono',
  NAME_REQUIRED: 'Inserisci un nome',
  YEAR_FOUNDATION_INVALID: 'Inserisci un anno valido',
  BOTTLES_PRODUCED_INVALID: 'Inserisci solo numeri',
  PASSWORD_INVALID: 'La password dovrebbe avere almeno 8 caratteri, 1 maiuscolo, 1 numero e 1 carattere speciale',
  IMAGE_SIZE: "L'immagine è più grande di 5 Mb",
  IMAGE_ERROR: "C'è stato un errore. Per favore prova nuovamente",
  URL_INVALID: "Inserisci una URL valida",
  INVALID_CREDENTIALS: "Le tue credenziali non sono valide"
};

const WINE_TYPES_EN = {
  'Rosso': 'Red',
  'Bianco': 'White',
  'Sparkling': 'Sparkling',
  'Rosato': 'Rose',
  'Dolce': 'Sweet'
}

export { ROLES , REGEX, ERROR_MESSAGES, WINE_TYPES_EN}