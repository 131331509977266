import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import RegisterForm from './components/register-form';
import InstructionCard from '../../components/instruction-card';
import classnames from 'classnames';
import './styles.scss';

const Register = () => {
  const [isRegistered, setIsRegistered] = useState(false);
  const generateContent = useCallback(() => {
    if (isRegistered) {
      return {
        title: 'Verifica e-mail',
        component: (
          <InstructionCard
            title='Controlla la tua casella di posta elettronica'
            subtitle='Ti è stata inviata una e-mail dove troverai le instruzioni
          per completare la tua registrazione.'
            footerText='La mail non è arrivata?'
            footerLink='/contact-form'
            footerLinkText='Contattaci'
          />
        ),
      };
    }
    return {
      title: 'Registrati',
      subtitle: (
        <>
          O{' '}
          <Link className='register__subtitle--link' to='/login'>
            Accedi
          </Link>
        </>
      ),
      component: <RegisterForm setIsRegistered={setIsRegistered} />,
    };
  }, [isRegistered]);

  const content = generateContent();

  return (
    <div className='register'>
      <div className='register__logo'>
        <img alt='logo' src={Logo} />
      </div>
      <h2 className={classnames('register__title', { 'mb-4': !content.subtitle})}>{content.title}</h2>
      <p
        className='register__subtitle'>
        {content.subtitle}
      </p>
      {content.component}
    </div>
  );
};

export default Register;
