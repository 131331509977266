import { useEffect, useRef, useState } from 'react';
import './styles.scss';

export const PopupMenu = ({ show = false, onClose, children }) => {
  const popupRef = useRef(null);

  // TODO: Improve outside click to close the menu
  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (popupRef.current && !popupRef.current.contains(event.target.value)) {
  //       console.log(event.target);
  //       if (onClose && show) {
  //         onClose();
  //       }
  //     }
  //   }
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     console.log('removed');
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [show, onClose]);

  if (!show) {
    return null;
  }

  return (
    <div className='popup-menu__container' ref={popupRef}>
      <div
        className='popup-menu__card-container'
        role='menu'
        aria-orientation='vertical'
        aria-labelledby='options-menu'
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};
