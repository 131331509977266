import * as Yup from 'yup';
import { ERROR_MESSAGES } from "../constants";

export const companyInformationSchema = Yup.object({
  name: Yup.string().required(ERROR_MESSAGES.NAME_REQUIRED),
  email: Yup.string().required(ERROR_MESSAGES.EMAIL_REQUIRED).email(ERROR_MESSAGES.EMAIL_INVALID),
  idRegion: Yup.number().positive(),
  province: Yup.string().required(ERROR_MESSAGES.PROVINCE_REQUIRED),
  city: Yup.string().required(ERROR_MESSAGES.CITY_REQUIRED),
  zipCode: Yup.string().required(ERROR_MESSAGES.ZIPCODE_REQUIRED),
  address: Yup.string().required(ERROR_MESSAGES.ADDRESS_REQUIRED),
  phone: Yup.string().required(ERROR_MESSAGES.PHONE_REQUIRED),
  yearFoundation: Yup.number().positive(ERROR_MESSAGES.YEAR_FOUNDATION_INVALID).integer(ERROR_MESSAGES.YEAR_FOUNDATION_INVALID).typeError(ERROR_MESSAGES.YEAR_FOUNDATION_INVALID),
  bottlesProduced: Yup.number().positive(ERROR_MESSAGES.BOTTLES_PRODUCED_INVALID).integer(ERROR_MESSAGES.BOTTLES_PRODUCED_INVALID).typeError(ERROR_MESSAGES.YEAR_FOUNDATION_INVALID),
});