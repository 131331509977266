import React from 'react';
import { useState, useEffect, useRef} from 'react';
import './styles.scss';
import classnames from 'classnames';

export const Autocomplete = ({
  label,
  styles,
  suggestions,
  onSelect,
  errorMessage,
  onChange,
  ...restProps
}) => {
  const [showList, setShowList] = useState(false);
  const [error, setError] = useState(errorMessage);
  const [selectedItem, setSelectedItem] = useState(null);
  const containerClasses = classnames('autocomplete', styles);
  const inputRef = useRef(null);

  useEffect(() => {
    if (onSelect) {
      onSelect(selectedItem)
    }
  }, [selectedItem, onSelect]);

  useEffect(() => {
    if (errorMessage) {
      setError(errorMessage);
    }
  }, [errorMessage]);

  return (
    <div className={containerClasses} >
      <label id='listbox-label' className='autocomplete__label'>
        {label}
      </label>
      <div className='autocomplete__input-container'>
        <input
          autocomplete="off"
          ref={inputRef}
          type='text'
          onClick={() => setShowList(true)}
          onFocus={() => setShowList(true)}
          onBlur={() => {
            setTimeout(() => {
              setShowList(false);
            }, 200);
          }}
          onChange={(event) => {
            setSelectedItem("");
            setError(false);
            onChange(event);
          }}
          {...restProps}
          className='input'
        />
        {error && (
          <p className="input__error-message">{errorMessage}</p>
        )}
        {(showList && suggestions.length > 0) && (
          <div className='autocomplete__list-container'>
            <ul
              tabIndex='-1'
              role='listbox'
              aria-labelledby='listbox-label'
              aria-activedescendant='listbox-item-3'
              className='list'
            >
              {suggestions.length > 0 &&
                suggestions.map((suggestion) => {
                  return (
                    <li
                      key={suggestion.id}
                      id='listbox-option-0'
                      role='option'
                      className='text-tertiary cursor-default select-none relative py-2 pl-3 pr-9'
                      onClick={(e) => {
                        e.preventDefault();
                        inputRef.current.value = suggestion.name;
                        setSelectedItem(suggestion);
                        setShowList(false);
                      }}
                    >
                      <span className='font-normal block truncate'>
                        {suggestion.name}
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
