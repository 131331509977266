import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch} from '@fortawesome/free-solid-svg-icons';
const LoadingScreen = () => (
  <div className='w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50'>
    <span
      className='opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0'
      style={{ top: '50%' }}
    >
      <FontAwesomeIcon className="text-primary" icon={faCircleNotch} size="6x" spin />
      {/* <i class='fas fa-circle-notch fa-spin fa-5x'></i> */}
    </span>
  </div>
);

export default LoadingScreen;
