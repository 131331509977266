import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetchProducer from '../../hooks/useFetchProducer.js';
import ProducerGallery from './components/producer-gallery/index.js';
import ProducerVideo from './components/producer-video/index.js';
import ProducerAdditionalInfo from './components/producer-additional-info/index.js';
import ProducerWinesLinkBar from './components/wines-link-bar'
import ProducerPresentation from '../../components/producer-presentation/index.js';
import { getProducerWinesPageLink } from '../../utils/producer'

const ProducerLanding = () => {
  let { id } = useParams();
  let producerId = parseInt(id);
  const [producer, isFetching] = useFetchProducer(producerId);
  const [carrouselImages, setCarrouselImages] = useState([]);
  useEffect(() => {
    if (producer) {
      const {
        carrousel1,
        carrousel2,
        carrousel3,
        carrousel4,
        carrousel5,
        carrousel6,
      } = producer;
      const arr = [
        carrousel1,
        carrousel2,
        carrousel3,
        carrousel4,
        carrousel5,
        carrousel6,
      ];
      setCarrouselImages(arr.filter(Boolean));
    }
  }, [producer]);


  return (
    <>
      {producer && (
        <>
          <ProducerPresentation producer={producer} isFetching={isFetching}>
            <ProducerWinesLinkBar link={getProducerWinesPageLink({ producerId })} />
            <ProducerVideo
              linkVideo={producer.linkVideo}
              linkWebsite={producer.linkWebsite}
            />
            <ProducerAdditionalInfo
              canBeVisited={producer.canBeVisited}
              companyTasting={producer.companyTasting}
              isDirectSell={producer.isDirectSell}
              linkMarketplace={producer.linkMarketplace}
              wineProperty={producer.wineProperty}
              viticoltura={producer.viticoltura}
              agronomo={producer.agronomo}
              ettari={producer.ettari}
              enologo={producer.enologo}
              bottlesProduced={producer.bottlesProduced}
            />
            <ProducerGallery carrouselImages={carrouselImages} />
          </ProducerPresentation>
        </>
      )}
    </>
  )
};

export default ProducerLanding;
