import './App.scss';
import { Routes } from './routes';

function App() {
  return (
    <div className='App'>
      <Routes />
    </div>
  );
}

export default App;
