import Button from '../../../components/button';
import { Collapse } from '../../../components/collapse';

const CollapseTasting = ({ note, note_en }) => {

  const LanguageSection = ({ lang, note }) => {

    if (!note) return null;
    return (
      <div className='my-2'>
        <h5>{lang}</h5>
        <p className='font-light'>{note}</p>
      </div>
    )
  }

  return (
    <Collapse title='Open tasting notes'>
      <div className='flex flex-col'>
        <LanguageSection lang='Italian' note={note}/>
        <LanguageSection lang='English' note={note_en}/>
      </div>
    </Collapse>
  )
}


export const LabelText = ({ label='', text='-', sizeLabel='md', sizeText='md' }) => {

  return (
    <div className="mt-2 mb-2">
      <span className={`text-${sizeLabel} text-gray-600 font-regular`}>{label}</span>
      <p className={`text-${sizeText} text-gray-600 font-light`}>{text}</p>
    </div>
  )
}

const TastingCard = ({ 
  wineName, 
  producerName, 
  appellation, 
  vintage=0,
  note='',
  note_en='',
  score=0,
  isBio=false,
  linkSticker='',
  linkShop='', 
  linkCertificate='',
}) => {
  const biological = isBio ? 'Yes' : 'No';
  return (
    <div className="m-5 px-5">
      <div className="bg-white max-w-lg rounded-2xl px-10 py-8 shadow-lg hover:shadow-2xl transition duration-500">
        <div className="flex">
          <div className="pr-2 w-4/12 flex flex-col items-center">
            <img className="max-h-80 max-w-20 mb-3" alt={score} src={linkSticker}></img>
            <Button size='small' type='link' href={linkSticker.replace('png', 'zip')} text='Download sticker' />
          </div>
          <div className="pl-2 w-8/12">
            <LabelText label="Producer" text={producerName} sizeText="lg" />
            <LabelText label="Wine name" text={wineName || '-'} sizeText="lg" />
            <LabelText label='Vintage' text={vintage || '-'} />
            <LabelText label="Appellation" text={appellation || '-'} />
            <LabelText label="Biological" text={biological} />
          </div>
        </div>

        <CollapseTasting note={note} note_en={note_en} />
       
        <div className="pt-4 flex justify-center md:justify-between flex-wrap ">
          <Button type='link' href={linkCertificate} text="Download certificate" />
          <Button type='link' href={linkShop} text="Shop" />
        </div>

      </div>
    </div>
  )
}

export default TastingCard;

