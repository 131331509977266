import './styles.scss';
import classnames from 'classnames';

export const CardTitle = ({children, styles}) => (
  <h5 className={classnames('card__title', styles)}>{children}</h5>
)

export const CardFooter = ({ children, styles }) => (
  <div className={classnames('card__footer', styles)}>
    {children}
  </div>
)
const Card = ({children}) => (
  <div className="card">
    {children}
  </div>
);

export default Card;