import { Link } from 'react-router-dom';
import './styles.scss';

const InstructionCard = ({title, subtitle, children, footerText, footerLink, footerLinkText}) => {

  return (
    <div className='instruction-card'>
      <h4 className='instruction-card__title'>{title}</h4>
      <div className="my-5 w-full border-t border-gray-300 border-solid"></div>
      {
        subtitle && (
          <p className='instruction-card__subtitle'>
            {subtitle}
          </p>
        )
      }
      {children}
      {(footerText && footerLinkText) && (
        <p className='instruction-card__footer'>
        {footerText} <Link className='link' to={footerLink}>{footerLinkText}</Link>
      </p>
      )}
    </div>
  );
};

export default InstructionCard;
