import { useEffect, useState } from 'react';
import { ROLES } from '../constants';
import { removeUser } from '../services/storageService';

export const useProvideUser = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [isPremiumUser, setIsPremiumUser] = useState(false);
  
  useEffect(() => {
    if (user) {
      if (user.role === ROLES.PREMIUM) {
        setIsPremiumUser(true);
      } else {
        setIsPremiumUser(false);
      }
    }
  }, [user]);

  const logOut = () => {
    removeUser();
    setUser(null);
  }

  return {
    setUser,
    user,
    isPremiumUser,
    logOut
  };
};
