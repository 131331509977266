import { useEffect, useState } from "react";
import { getWine, getWinesFromProducer, getTastingsFromWine } from '../services/visitor';

export const useFetchWines = (idProducer) => {
  const [wines, setWines] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsFetching(true)
      const { data } = await getWinesFromProducer(idProducer);
      setWines(data.wines);
      setIsFetching(false);
    }

    fetchData();
  }, [idProducer])
  
  return [wines, isFetching];
}

export const useFetchWine = (idWine) => {
  const [wine, setWine] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsFetching(true)
      const { data } = await getWine(idWine);
      setWine(data.wine);
      setIsFetching(false);
    }

    fetchData();
  }, [idWine])
  
  return [wine, isFetching];
}

export const useFetchWineTastings = (idWine) => {
  const [tastings, setTastings] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsFetching(true)
      const { data } = await getTastingsFromWine(idWine);
      setTastings(data.tastings);
      setIsFetching(false);
    }

    fetchData();
  }, [idWine])
  
  return [tastings, isFetching];
}