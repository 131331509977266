import { useState } from 'react';
import { Carousel } from '../../../../components/carousel';
import './styles.scss';

const ProducerGallery = ({ carrouselImages }) => {
  const [show, setShow] = useState(false);
  const [selectImage, setSelectedImage] = useState(0);

  const showCarousel = ({ imageIndex }) => {
    console.log('carrosel', imageIndex);
    setSelectedImage(imageIndex);
    setShow(true);
  };

  const smallImages = [1, 2, 5];

  return carrouselImages.length > 0 ? (
    <>
      <div className='producer-company'>
        <h5 className='producer-company__title'>Vineyard gallery</h5>
        <div className='producer-company__images'>
          {carrouselImages.map(
            (image, index) =>
              image && (
                <div
                  key={`carrousel-${index + 1}`}
                  className={`producer-company__image ${
                    smallImages.includes(index)
                      ? 'producer-company__image--small'
                      : ''
                  }`}
                >
                  <img
                    src={image}
                    alt={`carrousel-${index + 1}`}
                    onClick={() => showCarousel({ imageIndex: index })}
                  />
                </div>
              )
          )}
        </div>
      </div>
      {
        show && (
          <Carousel
            show
            onClose={() => setShow(false)}
            images={carrouselImages}
            selectedItem={selectImage}
          />

        )
      }
    </>
  ) : null;
};

export default ProducerGallery;
