import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ReactDOM from 'react-dom';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import './styles.scss';
import { useEffect } from 'react';

export const Carousel = ({ show, onClose, images, selectedItem }) => {
  const carouselProps = {
    emulateTouch: true,
    infiniteLoop: true,
    showIndicators: false,
    selectedItem,
    dynamicHeight: true,
    showThumbs: false,
  };

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key === 27 || e.keyCode === 27) {
        console.log('esc');
        onClose();
      }
    });
    return () =>
      document.removeEventListener('keydown', () => (e) => {
        if (e.key === 27 || e.keyCode === 27) {
          onClose();
        }
      });
  }, [onClose]);
  
  if (!show) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className='carousel' onClick={() => onClose()}>
      <div className='carousel__overlay'>
        <div className='carousel__content' onClick={(e) => e.stopPropagation()}>
          <ResponsiveCarousel {...carouselProps}>
            {images.length > 0
              ? images.map((image, index) => (
                <div
                    style={{ width: '100%', maxHeight: '550px' }}
                    key={`carrousel_img-${index}`}
                  >
                    <img src={image} alt={`carrousel-img-${index + 1}`} />
                  </div>
                ))
              : null}
          </ResponsiveCarousel>
        </div>
      </div>
    </div>,
    document.getElementById('root')
  );
};
