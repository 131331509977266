import './styles.scss';

const Header = ({ name, banner, logo }) => {
  return (
    <div className='header'>
      <div className='header__producer'>
        {logo && (
          <img
            className='producer-logo'
            src={logo}
            alt='producer-logo'
          />
        )}
        <h5 className='producer-name'>{name}</h5>
      </div>
      <div className='header__producer-cover'>
        {banner && (
          <img className='producer-cover' src={banner} alt='producer-cover' />
        )}
      </div>
    </div>
  );
};

export default Header;
