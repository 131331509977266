import { useEffect, useState } from 'react';
import CrownIcon from '../../assets/images/crown.svg';
import classnames from 'classnames';
import './styles.scss';

export const ToggleButton = ({ label, isActive = false, onChange, isPremiumFeature, styles, name, disabled }) => {
  const [activeButton, setActiveButton] = useState(isActive);
  useEffect(() => {
    setActiveButton(isActive);
  }, [isActive]);

  useEffect(() => {
    if (onChange && !disabled) {
      onChange(activeButton);
    }
  }, [activeButton, onChange, disabled]);

  return (
    <div className={classnames('toggle-button__container', styles)}>
      {
        isPremiumFeature && (
          <img className="toggle-button__accesory-icon" src={CrownIcon} alt="premium-icon" />
        )
      }
      {
        label && (
        <p className="toggle-button__label">{label}</p>
        )
      }
      <button
        type='button'
        className={`toggle-button ${activeButton ? 'toggle-button--enabled' : 'toggle-button--disabled'}`}
        aria-pressed='false'
        onClick={() => {
          if (!disabled) {
            setActiveButton(!activeButton);
          }
        }} 
      >
        <input className="toggle-button__input" name={name} value={activeButton} onChange={() => null}/>
        <span className='sr-only'>Use setting</span>

        <span className={`toggle-button__circle ${activeButton ? 'toggle-button__circle--enabled' : 'toggle-button__circle--disabled'}`}>
          <span
            className={`toggle-button__circle__cross ${activeButton ? 'toggle-button__circle__cross--enabled' : 'toggle-button__circle__cross--disabled'}`}
            aria-hidden='true'
          >
            <svg
              className='bg-white h-3 w-3 text-gray-400'
              fill='none'
              viewBox='0 0 12 12'
            >
              <path
                d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </span>

          <span
            className={`toggle-button__circle__tick ${activeButton ? 'toggle-button__circle__tick--enabled' : 'toggle-button__circle__tick--disabled'}`}
            aria-hidden='true'
          >
            <svg
              className='bg-white h-3 w-3 text-primary'
              fill='currentColor'
              viewBox='0 0 12 12'
            >
              <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
            </svg>
          </span>
        </span>
      </button>
    </div>
  );
};
