import { Route, Switch } from 'react-router';
import ChoosePassword from '../pages/choose-password';
import Dashboard from '../pages/dashboard';
import ForgetPassword from '../pages/forget-password';
import Login from '../pages/login';
import Register from '../pages/register';
import ContactForm from '../pages/contact-form';
import { AuthProvider } from '../providers/auth';
import { PrivateRoute } from '../components/authentication/private-route';
import ProducerLanding from '../pages/producer-landing';
import ProducerWinesPage from '../pages/producer-wines';
import WineTastingsPage from '../pages/wine-tastings';
import { ImpersonateUserPage } from '../pages/impersonate-user';

export const Routes = () => (
  <AuthProvider>
    <Switch>
      <Route path='/impersonate'>
        <ImpersonateUserPage />
      </Route>
      <Route path='/login'>
        <Login />
      </Route>
      <Route path='/register'>
        <Register />
      </Route>
      <Route path="/wine-producer/:pid/wines/:wid/tastings">
        <WineTastingsPage />
      </Route>
      <Route path="/wine-producer/:pid/wines">
        <ProducerWinesPage />
      </Route>
      <Route path="/wine-producer/:id">
        <ProducerLanding />
      </Route>
      <Route path='/contact-form'>
        <ContactForm />
      </Route>
      <Route path='/choose-password/:token'>
        <ChoosePassword />
      </Route>
      <Route path='/forget-password'>
        <ForgetPassword />
      </Route>
      <PrivateRoute path='/dashboard'>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path='/'>
        <Dashboard />
      </PrivateRoute>
    </Switch>
  </AuthProvider>
);
