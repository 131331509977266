import { useFormik } from 'formik';
import { useParams } from 'react-router';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import { ERROR_MESSAGES, REGEX } from '../../../../constants';
import { passwordValidator } from '../../../../utils/passwordValidator'
import { choosePassword } from '../../../../services/authService';
import './styles.scss';

const ChoosePasswordForm = ({ setPassword }) => {
  const { token } = useParams();
  
  const validate = values => {
    const errors = {};
    if (!values.password) {
      errors.password = 'Password è obbligatoria';
    } 
    else if (!passwordValidator(values.password)) {
      console.log('invalid');
      errors.password = ERROR_MESSAGES.PASSWORD_INVALID;
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Per favore conferma la password';
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords devono essere uguale';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validate,
    onSubmit: ({password}) => {
      choosePassword({ password, token })
        .then(resp => {
        if (resp.status === 200) {
          setPassword(true)
        }
        })
        .catch(error => {
          alert("C'è stato un errore");
        })
    }
  });

  return (
    <div className='choose-password__form'>
      <form className='w-full' onSubmit={formik.handleSubmit}>
        <Input
          styles="mb-6"
          type='password'
          name='password'
          onChange={formik.handleChange}
          errorMessage={formik.touched.password && formik.errors.password ? formik.errors.password : null}
          placeholder='Password'
        />
        <Input
          styles="mb-6"
          type='password'
          name='confirmPassword'
          onChange={formik.handleChange}
          errorMessage={formik.touched.confirmPassword && formik.errors.confirmPassword ? formik.errors.confirmPassword : null}
          placeholder='Conferma password'
        />
        <Button type='submit' maxWidth text='Salva' color='primary' />
      </form>
    </div>
  );
};

export default ChoosePasswordForm;
