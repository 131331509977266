import { useState, useCallback } from 'react';
import { useHistory } from "react-router";
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import ChoosePasswordForm from './components/choose-password-form';
import InstructionCard from '../../components/instruction-card';
import classnames from 'classnames';
import './styles.scss';
import Button from '../../components/button';

const ChoosePassword = () => {
  const [passwordChoosed, setPassword] = useState(false);
  const history = useHistory();
  
  const generateContent = useCallback(() => {

    if (passwordChoosed) {
      const content = (
        <div className="my-6 w-full">
          <Button text="Accedi" onClick={() => history.push('/login')}/>
        </div>
      );
      return {
        title: 'Verifica e-mail',
        component: (
          <InstructionCard
            title='La tua password è stata salvata'
            children={content}
          />
        ),
      };
    }
    return {
      title: 'Scegli la password',
      subtitle: (
        <>
          Torna alla {' '}
          <Link className='choose-password__subtitle--link' to='/register'>
            registrazione
          </Link>
        </>
      ),
      component: <ChoosePasswordForm setPassword={setPassword} />,
    };
  }, [passwordChoosed]);
  
  const content = generateContent();

  return (
    <div className='choose-password'>
      <div className='choose-password__logo'>
        <img alt='logo' src={Logo} />
      </div>
      <h2 className={classnames('choose-password__title', { 'mb-4': !content.subtitle})}>{content.title}</h2>
      <p
        className='choose-password__subtitle'>
        {content.subtitle}
      </p>
      {content.component}
    </div>
  );
};

export default ChoosePassword;
