import { useEffect, useRef, useState } from 'react';
import UploadImageIcon from '../../assets/images/upload-image.svg';
import { ERROR_MESSAGES } from '../../constants';
import { uploadImage } from '../../services/fileService';
import './styles.scss';

export const UploadImage = ({ fieldName, image = null }) => {
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(image);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setSelectedImage(image)
  }, [image])

  const handleFileSubmit = () => {
    const maxSize = 1024 * 1024 * 5;
    const file = fileInputRef.current ? fileInputRef.current.files[0] : null;
    if (file) {
      if (file.size > maxSize) {
        return setError(ERROR_MESSAGES.IMAGE_SIZE);
      }
      setSelectedImage(URL.createObjectURL(file));

      uploadImage({ file, fieldName })
        .then((resp) => {})
        .catch((e) => {
          setError(ERROR_MESSAGES.IMAGE_ERROR);
          setSelectedImage(null);
        });
    }
  };
  return (
    <>
      <div
        className='upload-image'
        style={{ backgroundImage: `url(${error ? '' : selectedImage})`}}
        onClick={() => fileInputRef.current.click()}
      >
        <input
          type='file'
          className='hidden'
          accept='image/x-png,image/jpeg'
          ref={fileInputRef}
          onChange={handleFileSubmit}
        />
        {!selectedImage && (
          <>
            <img src={UploadImageIcon} alt='upload-icon' />
            <p className='upload-image__title'>Upload a file</p>
            <p className='upload-image__subtitle'>PNG, JPG up to 5 MB</p>
          </>
        )}
      </div>
      {error && <p className='upload-image__error-message'>{error}</p>}
    </>
  );
};
