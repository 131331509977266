import { useState } from 'react';
import CrownIcon from '../../assets/images/crown.svg';
import { Modal } from '../modal';
import { PremiumModalContent } from '../premium-modal-content';
import './styles.scss';

export const PremiumButton = ({ withModal }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <button onClick={() => setShowModal(true)} type='button' id="premium-button" className='premium-button'>
        <img
          src={CrownIcon}
          className='premium-button__icon'
          alt='premium-icon'
        />
        <p className='premium-button__text'>Passa a premium</p>
      </button>
      {withModal && (
        <Modal show={showModal} onClose={() => setShowModal(false)}>
          <PremiumModalContent />
        </Modal>
      )}
    </>
  );
};
