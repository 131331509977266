import InstructionCard from '../../../components/instruction-card';

const RetrievePasswordMessage = () => {

  return (
    <InstructionCard
      title='Controlla la tua mail'
      subtitle='Ti è stata inviata una e-mail dove troverai le instruzioni per il
    recupero password.'
      footerText="La mail non è arrivata?"
      footerLink="/contact-form"
      footerLinkText="Contattaci"
    />
  );
};

export default RetrievePasswordMessage;
