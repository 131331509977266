import './styles.scss';

export const VideoUrl = ({ input }) => (
  <div className="video-url">
    <p className="video-url__title">Video</p>
    <div className="video-url__url">
      {input}
    </div>
    <p className="video-url__leyend">Consigliati Vimeo, youtube</p>
  </div>
)