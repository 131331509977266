import Logo from '../../assets/images/logo.svg';
import Edit from '../../assets/images/edit.svg';
import Plus from '../../assets/images/plus.svg';
import Translate from '../../assets/images/translate.svg';
import Clip from '../../assets/images/clip.svg';
import Geolocation from '../../assets/images/geolocation.svg';
import Crown from '../../assets/images/crown.svg';

import './styles.scss';
import { upgradePlan } from '../../services/userService';
import { useState } from 'react';
export const PremiumModalContent = () => {
  const [message, setMessage] = useState(null);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const listItems = [
    {
      id: 'editicon-0',
      icon: Edit,
      content:
        'Interfaccia Migliorata con Account personalizzato ed inserimento di materiale informativo per gli lettori addetti ai lavori: Biografia, Fotografie di Vigna, Cantina e Proprietà, Anno di Nascita, Ettari Vitati, Tipo di Conduzione Enologo, Capacità produttive e Figure professionali ecc…',
    },
    {
      id: 'plusicon-1',
      icon: Plus,
      content:
        'Campioni in degustazione WinesCritic.com e Internationalwinereport.com Illimitati',
    },
    {
      id: 'translateicon-2',
      icon: Translate,
      content:
        'Traduzioni Note in Inglese per invio diretto ad Internationalwinereport.com e Wine-Searcher.com con caricamento sulle piattaforme per i vini con punteggi superiori ai 90 punti.',
    },
    {
      id: 'clipicon-3',
      icon: Clip,
      content:
        'Collegamento diretto ad Account Social ed e-shop interni ed esterni per incentivare la vendita a lettori e consumatori.',
    },
    {
      id: 'geolocationicon-4',
      icon: Geolocation,
      content:
        'Collegamento diretto ad Account Social ed e-shop interni ed esterni per incentivare la vendita a lettori e consumatori.',
    },
  ];

  const handleUpagredPlan = (e) => {
    e.preventDefault();
    setIsSubmiting(true);
    upgradePlan()
      .then((resp) =>
        setMessage(
          'La tua richiesta è stata inviata ed è in attesa di approvazione da parte di un amministratore'
        )
      )
      .catch((e) => setMessage("C'è stato un errore, please try again later"))
      .finally(() => setTimeout(() => setMessage(null), 5000))
      .finally(() => setIsSubmiting(false));
  };

  return (
    <div className='premium-modal-content'>
      <div className='premium-modal-content__title'>
        <img
          src={Logo}
          alt='logo'
          className='premium-modal-content__title__image'
        />
        <h4 className='premium-modal-content__title__text'>
          Perchè passare a premium?
        </h4>
      </div>
      <p className='premium-modal-content__caption'>
        {message
          ? message
          : 'L’Account Premium dona una serie di privilegi e servizi aggiuntivi che permettono di aumentare la percezione dell’azienda presente nel database di WinesCritic.com, alcuni di questi sono:'}
      </p>
      {!message && (
        <div className='premium-modal-content__list'>
          {listItems.map((item) => (
            <div key={item.id} className='list__item'>
              <img
                width='20'
                src={item.icon}
                alt='list-icon'
                className='list__item__icon'
              />
              <p className='list__item__text'>{item.content}</p>
            </div>
          ))}
        </div>
      )}
      <div className='premium-button'>
        <button
          onClick={(e) => handleUpagredPlan(e)}
          type='button'
          className='premium-button__button'
        >
          <img
            src={Crown}
            className='premium-button__icon'
            alt='premium-icon'
          />
          <p className='premium-button__text'>Passa a premium</p>
        </button>
      </div>
    </div>
  );
};
