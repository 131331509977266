import { Link } from 'react-router-dom';
import House from '../../../../assets/images/house.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

const NavLinks = ({producerId}) => {
  const navLinks = [
  //   {
  //     to: '',
  //     name: 'Profilo azienda',
  //     icon: MultipleUsers,
  //  },
    {
      to: '/dashboard',
      target: '_self',
      name: 'Profilo',
      icon: House,
   },
    {
      to: `/wine-producer/${producerId}`,
      target: '_blank',
      name: 'Vai al sito pubblico',
      fontIcon: faLink,
   },
  //   {
  //     to: '',
  //     name: 'Vetrina',
  //     icon: PaperList,
  //  },
  //   {
  //     to: '',
  //     name: 'Vini',
  //     icon: WineBottle,
  //  },
  ]
  
  return (
    <ul className="nav-links">
      {navLinks.map((navLink, index) => (
        <li key={navLink.name} className="">
          {index === (navLinks.length - 2) && (
            <div className="separator"></div>
          )}
          <Link to={navLink.to} target={navLink.target} className="nav-link">
            {navLink.fontIcon && (<FontAwesomeIcon className="mr-5" icon={navLink.fontIcon}/>) }
            {navLink.icon && (<img className="nav-link__icon" src={navLink.icon} alt={`${navLink.name}-icon`} />)}
            <p className="nav-link__name">{navLink.name}</p>
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default NavLinks;
