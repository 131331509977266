import './styles.scss';
import classnames from 'classnames';

const SIZES = {
  sm: 'small',
  md: 'medium',
  lg: 'large'
}

const Button = ({
  text,
  type = 'button',
  color = 'primary',
  hierarchy = 'loud',
  size=SIZES.md,
  maxWidth,
  disabled,
  styles,
  ...buttonProps
}) => {
  const classes = classnames(
    'button',
    {
      'button--primary': color === 'primary',
      'button--secondary': color === 'secondary',
      'button--default': color === 'default',
      'button--quiet': hierarchy === 'quiet',
      'button--large': size === SIZES.lg,
      'button--small': size === SIZES.sm,
      'w-full': maxWidth,
      'cursor-pointer': type === 'link',
    },
    styles,
    
  );
  return type === 'link' ? (
    <a {...buttonProps} className={classes}>
      {text}
    </a>
  ) : (
    <button {...buttonProps} className={classes}>
      {text}
    </button>
  );
};

export default Button;
