import Button from '../../../../components/button';
import YoutubeIframe from '../../../../assets/images/youtube.png';
import ReactPlayer from 'react-player';
import './styles.scss';

const ProducerVideo = ({ linkVideo, linkWebsite }) => {
  const embededId = linkVideo && String(linkVideo).split('v=')[1];
  const youtubeUrl = `https://www.youtube.com/embed/${embededId}`;
  return (
    <>
      <div className='producer-video'>
        <div className='w-3/5'>
          {linkVideo ? (
            <ReactPlayer width="100%" height="100%" url={linkVideo} />
            ) : (
              <img src={YoutubeIframe} alt="Youtube video"/>
            )
          }
        </div>
      </div>
      {linkWebsite && (
        <div className="flex flex-row justify-end">
          <div className='producer-website'>
            <Button type="link" styles='mr-8' text='Go' href={linkWebsite.includes('http') ? linkWebsite : `https://${linkWebsite}`}/>
            <p className='text'>Visit our Website</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ProducerVideo;
