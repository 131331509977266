import Header from './components/header/index.js';
import ProducerInfo from './components/producer-info/index.js';
import LoadingScreen from '../loading/index.js';

const ProducerPresentation = ({ children, producer, isFetching }) => {
  
  return isFetching ? (
    <LoadingScreen />
  ) : (
    <>
      {producer && (
        <div>
          <Header
            name={producer.name}
            banner={producer.banner}
            logo={producer.logo}
          />
          <ProducerInfo
            yearFoundation={producer.yearFoundation}
            region={producer.region.name}
            linkFacebook={producer.linkFacebook}
            linkInstagram={producer.linkInstagram}
            fullAddress={producer.fullAddress}
            email={producer.email}
            phone={producer.phone}
            isPremium={producer.isPremium}
          />
          { children }
          <div className='py-10'>
            {/* {'Footer'} */}
          </div>
        </div>
      )}
    </>
  );
};

export default ProducerPresentation;
