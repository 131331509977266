import { useLayoutEffect, useState } from 'react';
import Logo from '../../components/logo';
import { PremiumButton } from '../../components/premium-button';
import { UserMenu } from '../../components/user-menu';
import { useAuth } from '../../hooks/useAuth';
import { getProducer } from '../../services/producerService';
import CompanyInformation from './components/company-information';
import MainNav from './components/main-nav';
import NavBar from './components/nav-bar';
import NavLinks from './components/nav-links';
import UserProfile from './components/user-profile';

import './styles.scss';

const Dashboard = () => {
  const { user, isPremiumUser } = useAuth();
  const [producerInfo, setProducerInfo] = useState(user);

  useLayoutEffect(() => {
    getProducer({ idProducer: user.idProducer })
      .then((resp) => {
        const data = resp.data;
        
        setProducerInfo(data.producer);
      })
      .catch((e) => {});
  }, [user]);
  
  return (
    <div className='dashboard'>
      <div className='dasboard__left-content'>
        <MainNav>
          <Logo />
          <NavLinks producerId={user.idProducer} />
        </MainNav>
      </div>
      <div className='dashboard__main-content'>
        <NavBar>
          {!isPremiumUser ? <PremiumButton withModal /> : null}
          <UserMenu user={user} />
        </NavBar>
        <div className='dashboard__main-content__content'>
          <CompanyInformation user={producerInfo} isPremium={isPremiumUser}/>
          <UserProfile user={producerInfo} isPremium={isPremiumUser}/>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
