import { restClient } from "../utils/restClient"
import { retrieveUser } from "./storageService";

export const uploadImage = ({ file, fieldName }) => {
  
  const { idProducer: userId } = retrieveUser();
  
  let headers = {};
  headers['Content-Type'] = 'x-www-form-urlencoded';
  const body = new FormData();
  body.append('file', file);
  body.append('field', fieldName);

  return restClient.post(`/producers/${userId}/files`, body, headers);
}