import CrownIcon from '../../assets/images/crown.svg';
import './styles.scss';
import classnames from 'classnames';

const Input = ({
  type = 'text',
  styles,
  placeholder,
  onChange,
  status,
  name,
  disabled,
  isPremiumFeature,
  errorMessage,
  width = 'full',
  ...inputProps
}) => {
  const fieldProps = {
    ...inputProps,
    id: name,
    name,
    type,
    disabled: disabled || null,
    placeholder: placeholder || null,
    onChange: onChange || null,
  };

  const handleOpenPremiumModal = () => {
    if (disabled && isPremiumFeature) {
      const button = document.getElementById('premium-button');
      button.click();
    }
  }

  const content =
    type !== 'textarea' ? (
      <div className={classnames('input-container', styles)} onClick={handleOpenPremiumModal}>
        <div className="relative">
          <input autoComplete='disabled' className='input' {...fieldProps} />
          {isPremiumFeature && (
            <div className='input__icon-container'>
              <img className='input__icon' src={CrownIcon} alt='premium-icon' />
            </div>
          )}
        </div>
        {errorMessage && <p className='input__error-message'>{errorMessage}</p>}
      </div>
    ) : (
      <>
        <textarea
          className={classnames('textarea', 'input', styles)}
          {...fieldProps}
        />
        {errorMessage && (
          <p className='textarea__error-message'>{errorMessage}</p>
        )}
      </>
    );

  return content;
};

export default Input;
