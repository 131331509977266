import { restClient } from '../utils/restClient';

export const getProducers = () => {
  return restClient.get('/producers');
};

export const updateProducer = (fields, producer, isPremium) => {
  const { id } = producer;
  const standardBody = {
    name: fields.name,
    email: fields.email,
    country: fields.country,
    idRegion: fields.idRegion,
    city: fields.city,
    province: fields.province,
    zipCode: fields.zipCode,
    address: fields.address,
    phone: fields.phone
  };

  const premiumBody = {
    ...standardBody,
    yearFoundation: Number(fields.yearFoundation)
      ? Number(fields.yearFoundation)
      : null,
    wineProperty: fields.wineProperty,
    agronomo: fields.agronomo,
    enologo: fields.enologo,
    ettari: fields.ettari,
    bottlesProduced: fields.bottlesProduced,
    viticoltura: fields.viticoltura,
    canBeVisited: fields.canBeVisited,
    isDirectSell: fields.isDirectSell,
    companyTasting: fields.companyTasting,
  };

  const body = isPremium ? premiumBody : standardBody;
  
  return restClient.patch(`/producers/${id}/information`, body);
};

export const updateProducerShowcase = ({ fields, producer, isPremium }) => {
  const { id } = producer;
  const standardBody = {
    linkFacebook: fields.linkFacebook,
    linkInstagram: fields.linkInstagram,
    linkWebsite: fields.linkWebsite,
  };

  const premiumBody = {
    ...standardBody,
    linkMarketplace: fields.linkMarketplace,
    linkVideo: fields.linkVideo,
  };

  const body = isPremium ? premiumBody : standardBody;
  return restClient.patch(`/producers/${id}/showcase`, body);
};

export const getProducer = ({ idProducer }) => {
  return restClient.get(`/producers/${idProducer}`);
};
