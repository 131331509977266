import { useFormik } from 'formik';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import Button from '../../../../components/button';
import Card, { CardTitle, CardFooter } from '../../../../components/card';
import Input from '../../../../components/input';
import { REGEX } from '../../../../constants';
import { showcaseSchema } from '../../../../schemas/showcase';
import { updateProducerShowcase } from '../../../../services/producerService';
import { CarousellPhotos } from './components/carousell-photos';
import { CoverPhoto } from './components/cover-photo';
import { ProfileLogo } from './components/profile-logo';
import { ShopSite } from './components/shop-site';
import { SocialProfile } from './components/social-profile';
import { VideoUrl } from './components/video-url';
import { WebSite } from './components/web-site';
import './styles.scss';

const UserProfile = ({ user, isPremium }) => {
  const [updatedMessage, setUpdatedMessage] = useState(null);
  useEffect(() => {
    if (updatedMessage) {
      setTimeout(() => setUpdatedMessage(null), 5000);
    }
  }, [updatedMessage]);

  let formik = useFormik({
    initialValues: {
      linkFacebook: '',
      linkInstagram: '',
      linkWebsite: '',
    },
    validationSchema: showcaseSchema,
    onSubmit: (values) => {
      updateProducerShowcase({ fields: values, producer: user, isPremium })
        .then((resp) => {
          setUpdatedMessage('Profilo aggiornato');
        })
        .catch((e) => setUpdatedMessage("C'è stato un errore"));
    },
  });

  const changeFieldValues = useCallback(() => {
    formik.setFieldValue('linkFacebook', user.linkFacebook ? user.linkFacebook : '')
    formik.setFieldValue('linkInstagram', user.linkInstagram ? user.linkInstagram : '')
    formik.setFieldValue('linkWebsite', user.linkWebsite ? user.linkWebsite : '')
    formik.setFieldValue('linkMarketplace', user.linkMarketplace ? user.linkMarketplace : '')
    formik.setFieldValue('linkVideo', user.linkVideo ? user.linkVideo : '')
  }, [user])

  useLayoutEffect(() => {
    changeFieldValues();
  }, [user, changeFieldValues]);

  return (
    <div className='user-profile'>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardTitle>Profilo</CardTitle>
          <div className='user-profile__social-profile'>
            <SocialProfile
              inputs={
                <div className='social-webs'>
                  <Input
                    styles='mr-4'
                    placeholder='Faceboook URL profilo social'
                    name='linkFacebook'
                    onChange={formik.handleChange}
                    value={formik.values.linkFacebook}
                    errorMessage={
                      formik.touched.linkFacebook && formik.errors.linkFacebook
                        ? formik.errors.linkFacebook
                        : null
                    }
                  />
                  <Input
                    styles='ml-4'
                    placeholder='Instagram URL profilo social'
                    name='linkInstagram'
                    onChange={formik.handleChange}
                    value={formik.values.linkInstagram}
                    errorMessage={
                      formik.touched.linkInstagram &&
                      formik.errors.linkInstagram
                        ? formik.errors.linkInstagram
                        : null
                    }
                  />
                </div>
              }
            />
          </div>
          <div className='user-profile__web-site'>
            <WebSite
              inputs={
                <Input
                  placeholder='URL sito web'
                  name='linkWebsite'
                  onChange={formik.handleChange}
                  value={formik.values.linkWebsite}
                  errorMessage={
                    formik.touched.linkWebsite && formik.errors.linkWebsite
                      ? formik.errors.linkWebsite
                      : null
                  }
                />
              }
            />
          </div>
          <div className='user-profile__shop-site'>
            <ShopSite
              inputs={
                <Input
                  placeholder='URL shop azienda'
                  name='linkMarketplace'
                  onChange={formik.handleChange}
                  value={formik.values.linkMarketplace}
                  isPremiumFeature
                  disabled={!isPremium}
                  errorMessage={
                    formik.touched.linkMarketplace &&
                    formik.errors.linkMarketplace
                      ? formik.errors.linkMarketplace
                      : null
                  }
                />
              }
            />
          </div>
          <div className='user-profile__profile-logo'>
            <ProfileLogo logo={user.logo} />
          </div>
          <div className='user-profile__cover-photo'>
            <CoverPhoto cover={user.banner} />
          </div>
          <div className='user-profile__video-url'>
            <VideoUrl
              input={
                <Input
                  placeholder='Link video'
                  name='linkVideo'
                  onChange={(e) => {
                    
                    formik.handleChange(e)
                  }}
                  value={formik.values.linkVideo}
                  disabled={!isPremium}
                  isPremiumFeature
                  errorMessage={
                    formik.touched.linkVideo &&
                    formik.errors.linkVideo
                      ? formik.errors.linkVideo
                      : null
                  }
                />
              }
            />
          </div>
          <div className='user-profile__carousell-photos'>
            <CarousellPhotos
              photos={[
                user.carrousel1,
                user.carrousel2,
                user.carrousel3,
                user.carrousel4,
                user.carrousel5,
                user.carrousel6,
              ]}
            />
          </div>
          {updatedMessage && (
            <p className='feedback__message'>{updatedMessage}</p>
          )}
          <CardFooter>
            <Button text='Salva' type='submit' />
          </CardFooter>
        </Card>
      </form>
    </div>
  );
};

export default UserProfile;
