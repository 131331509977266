import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkedAlt,
  faEnvelope,
  faMapMarker,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import bannerVerified from '../../../../assets/images/banner_verified.png'
import bannerStandard from '../../../../assets/images/banner_standard.png'
import './styles.scss';


const IconLabel = ({ label, value, faIcon }) => {

  return (
    <div className='flex flex-col sm:flex-col justify-start mb-4 w-full pr-4'>
      {/* <div className='icon-container'>
        <FontAwesomeIcon
          className=' hidden sm:block text-primary text-lg mr-4'
          icon={faIcon}
        />
      </div> */}
      <p className='text-lg font-bold mr-2 min-w-max'>{label || '-'}</p>
      <p className='text-lg'>{value || '-'}</p>
    </div>
  )
}

const ProducerInfo = ({
  yearFoundation,
  region,
  linkFacebook,
  linkInstagram,
  email,
  phone,
  fullAddress,
  isPremium,
}) => {

  let planTypeStyle = `producer-info__${isPremium ? 'verified' : 'standard' }`;

  const social = (
    <div className='flex flex-row'>
      <a href={linkFacebook}>
        <FontAwesomeIcon
          className='text-primary mr-4 sm:text-3xl cursor-pointer'
          icon={faFacebook}
        />
      </a>
      <a href={linkInstagram}>
        <FontAwesomeIcon
          className='text-primary mr-4 sm:text-3xl cursor-pointer'
          icon={faInstagram}
        />
      </a>
    </div>
  )

  return (
    <div className={`${planTypeStyle} flex flex-col max-w-full bg-gray-200`}>
      <div className='w-full py-2 sm:pl-20 sm:w-4/5 bg-tertiary sm:rounded-r-2xl sm:py-4 sm:-top-8'>
        <p className='font-primary text-lg 2xl:text-xl'>
          We have been producing wine since {yearFoundation || 'N/A'}
        </p>
      </div>
      <div className='flex flex-row justify-center py-4'>
        <div className='flex flex-col sm:flex-row w-4/5'>
          <div className='w-1/2'>
            <h5 className='text-xl font-bold my-2'>Where are we</h5>
            <div className='flex flex-col'>
              <IconLabel label='Region of production' value={region} faIcon={faMapMarker} />
              <IconLabel label='Address' value={fullAddress} faIcon={faMapMarkedAlt} />
            </div>
          </div>
          <div className='producer-contacts'>
            <h5 className='text-xl font-bold my-2'>Contacts</h5>
            <div className='flex flex-col'>
              <IconLabel label='Email' value={email} faIcon={faEnvelope} />
              <IconLabel label='Phone' value={phone} faIcon={faPhone} />
              <IconLabel label='Social' value={social}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ProducerInfo;
