import Icon from '../../assets/images/logo.svg';
import './styles.scss';

const Logo = ({ className, width = '60px', height, ...restProps }) => {

  return (
    <div className="logo-container">
      <img className={className} width={width} height={height} src={Icon} {...restProps} alt="company-logo" />
      <span className="logo__title">Winescritic</span>
    </div>
  );
}

export default Logo;
