import { restClient } from '../utils/restClient';

export const loginService = ({ email, password }) => {
  
  const body = {
    email,
    password
  }
  return restClient.post('/auth/login', body);
}

export const registerService = ({ firstName, lastName, email, phone, producer }) => {
  
  const body = {
    firstName,
    lastName,
    email,
    phone,
    idProducer: producer.id,
  }

  return restClient.post('/auth/signup', body);
}

export const choosePassword = ({ password, token }) => {
  
  const body = {
    password,
    passwordToken: token
  }

  return restClient.post('/auth/change-password', body);
}

export const forgotPassword = ({ email }) => {
  const body = {
    email
  };

  return restClient.post('/auth/forgot-password', body);
}

export const resetPassword = () => {

  return restClient.post('/auth/reset-password');
}